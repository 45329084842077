<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="indigo--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="indigo" @click="displayCreateRealm = true" v-if="add || create">
                <font-awesome-icon icon="plus" style="font-size: 20px;" fixed-width/>
            </v-btn>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No realms.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <RealmListItem :attr="item" :link="!selectOne"></RealmListItem>
            </v-list-item>
        </v-list>
        <v-row justify="center" class="py-5" v-show="displayCreateRealm">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-form v-model="createRealmForm" ref="createRealmFormRef" @submit="validateCreateRealm" onSubmit="return false;" @keyup.enter.native="validateCreateRealm">
                    <div v-if="add">
                        <v-select :items="addRealmChoices" v-model="addRealmId" label="Select an realm"></v-select>
                    </div>
                    <div v-if="create">
                        <!--
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="indigo"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="realm" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        -->
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newRealmName
                            label="Display Name"
                            :rules="newRealmNameRules"
                            validate-on-blur
                            color="indigo"
                            required
                            hint="The name for the realm"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="indigo white--text" @click="addRealm" :disabled="!createRealmForm" v-if="add">
                                <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="indigo white--text" @click="createRealm" :disabled="!createRealmForm" v-if="create">
                                <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import RealmListItem from '@/components/RealmListItem.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        RealmListItem,
    },

    props: {
        // optional; use this when you want to use the realm list to select an realm; emits 'selected' when user taps on an realm
        selectOne: {
            type: Boolean,
            default: false,
        },
        // optional; true means the 'plus' button will be for adding a user to a list
        add: {
            type: Boolean,
            default: false,
        },
        // optional; true means the 'plus' button will be allowed to create new user records if they don't already exist
        create: {
            type: Boolean,
            default: false,
        },
        // policyId is optional; when provided, the create realm dialog functions as an 'add or create' dialog and allows user to choose from an existing realm to add to the list, or create a new realm
        policyId: {
            type: String,
        },
        list: {
            type: Array,
        },
    },

    data() {
        return {
            // list: [],
            // create realm
            displayCreateRealm: false,
            createRealmForm: null,
            newRealmName: null,
            newRealmNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'Realm name is required',
            ],
            // add realm
            addRealmChoices: [],
            addRealmId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an realm';
            }
            switch (this.list.length) {
            case 0:
                return 'No realms';
            case 1:
                return '1 realm';
            default:
                return `${this.list.length} realms`;
            }
        },
    },

    methods: {
        onClickItem(realmId) {
            if (this.selectOne) {
                this.$emit('selected', { realmId });
            }
        },
        async createRealm() {
            this.error = false;
            console.log('createRealm');
            const request = {
                // name: this.name,
                name: this.newRealmName,
                // agreeToTerms: this.isAgreeToTermsChecked,
                // interactionId: this.interactionId, // will be present if realm arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the realm to an appropriate location after realm is created
            };
            const response = await this.$client.realm.create(request);
            console.log('createRealm response: %o', response);
            const { isCreated, id, error } = response;
            if (isCreated) {
                const realm = { id, ...request };
                this.list.push(realm);
                this.$emit('created-realm', realm);
                this.$emit('added-realm', realm);
                this.displayCreateRealm = false;
                this.newRealmName = null;
            } else if (error) {
                this.error = error;
            } else {
                this.error = 'Request failed';
            }
        },
        validateCreateRealm() {
            if (this.$refs.createRealmFormRef.validate()) {
                this.createRealm();
            }
        },

        async addRealm() {
            const realm = { id: this.addRealmId, label: this.addRealmChoices.find((item) => item.value === this.addRealmId).text };
            this.list.push(realm);
            this.$emit('added-realm', realm);
            this.displayCreateRealm = false;
            this.addRealmId = null;
        },
        async initAddRealmChoices() {
            // create a map of which realm ids are already in the list
            const realmMap = {};
            this.list.forEach((item) => {
                realmMap[item.id] = true;
            });
            // when we show the add/create realm dialog, load the list of available realms to add to the policy (list of all realms less the ones already in the policy)
            const result = await this.$client.realm.list();
            console.log(`RealmViewList.vue fetch realms result: ${JSON.stringify(result)}`);
            if (result && result.list) {
                // filter the results to remove realms already in the policy, then convert the available realms to a choice list for the v-select [ { text, value }, ... ]
                this.addRealmChoices = result.list.filter((item) => !realmMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
            }
        },
    },

    watch: {
        displayCreateRealm(value) {
            if (value && (this.add || this.create)) {
                this.initAddRealmChoices();
            }
        },
    },
};
</script>
